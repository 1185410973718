import React, { Suspense, useEffect, useState } from 'react'
import {
  ContentWarpper,
  PricingWrapper,
  SchoolOpenWrapper,
  ActivityContentWrapper,
  CountDownWrapper,
  // PirceTitle,
  // PriceBannerWarrper,
} from './atoms'
import PriceCard from './components/price-card'
import CreditCard from './components/credit-card'
import { Layout } from '@raylink-overseas/common/components'
import Service from './components/service'
import Question from './components/question'
import TopBtn from '@raylink-overseas/common/components/top-btn'
import PriceTabs, { userTypes } from './components/price-tabs'
import ComparePlans from './components/compare-plans'
import ActivityBgImg from '../../images/pricing/price-bg.png'
import ActivityIpadBgImg from '../../images/pricing/price-ipad-bg.svg'
import ActivityMobileBgImg from '../../images/pricing/price-mobile-bg.png'
import ActivityTitleImg from '../../images/pricing/price-title.svg'
import ActivityTitleMobileImg from '../../images/pricing/price-title-mobile.svg'
import colonImg from '../../images/pricing/colon.svg'

export type UserType = {
  title: string
  tabDescription: string
}

export default function PricingPage() {
  const [countDownInfo, setCountDownInfo] = useState<
    { time: string | number; unit: string }[]
  >([
    { time: 0, unit: 'Days' },
    { time: 0, unit: 'Hours' },
    { time: 0, unit: 'Minutes' },
    { time: 0, unit: 'Seconds' },
  ])
  useEffect(() => {
    // 活动结束时间为2024年9月26日
    const endTime = new Date('2024-09-26T00:00:00').getTime()
    const timer = setInterval(() => {
      const now = new Date().getTime()
      const distance = endTime - now
      if (distance <= 0) {
        setCountDownInfo([
          { time: 0, unit: 'Days' },
          { time: 0, unit: 'Hours' },
          { time: 0, unit: 'Minutes' },
          { time: 0, unit: 'Seconds' },
        ])
        clearInterval(timer)
        return
      }
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      const format = (num: number) => (num < 10 ? `0${num}` : num)
      setCountDownInfo([
        { time: format(days), unit: 'Days' },
        { time: format(hours), unit: 'Hours' },
        { time: format(minutes), unit: 'Minutes' },
        { time: format(seconds), unit: 'Seconds' },
      ])
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <Layout pageType="pricing">
      <PricingWrapper>
        <div className="half-wrapper">
          {/** 开学季活动 */}
          <SchoolOpenWrapper>
            <img
              className="web-bg"
              src={ActivityBgImg}
              alt="School open activity"
              loading="lazy"
            />
            <img
              className="ipad-bg"
              src={ActivityIpadBgImg}
              alt="School open activity"
              loading="lazy"
            />
            <img
              className="mobile-bg"
              src={ActivityMobileBgImg}
              alt="School open activity"
              loading="lazy"
            />
            <ActivityContentWrapper>
              <img
                className="activity-title"
                src={ActivityTitleImg}
                alt="School open activity"
                loading="lazy"
              />
              <img
                className="activity-title-mobile"
                src={ActivityTitleMobileImg}
                alt="School open activity"
                loading="lazy"
              />
              <CountDownWrapper>
                <div className="count-down-title">Special Offer Ends In</div>
                <div className="count-down">
                  {countDownInfo.map((item, index) => (
                    <Suspense key={index}>
                      <div className="count-down-item">
                        <div className="time-wrapper">
                          <div className="time">{item.time}</div>
                          {index < countDownInfo.length - 1 && (
                            <img src={colonImg} />
                          )}
                        </div>
                        <div className="unit">{item.unit}</div>
                      </div>
                    </Suspense>
                  ))}
                </div>
              </CountDownWrapper>
            </ActivityContentWrapper>
          </SchoolOpenWrapper>
          <ContentWarpper>
            {/** 开学季活动 */}
            {/* <PirceTitle>
              Select Your Plan of Avica <span>Personal</span>
            </PirceTitle> */}
            <PriceTabs type={Object.keys(userTypes)[0]} />
            {/* <a
              href="/promo-campaign.html?utm_source=website&utm_medium=display&utm_campaign=2024olympic_pricing_banner"
              target="_blank"
            >
              <PriceBannerWarrper />
            </a> */}
            <PriceCard isPersonalMode />
            <CreditCard />
          </ContentWarpper>
        </div>
        <ContentWarpper>
          <Service type={Object.keys(userTypes)[0]} />
        </ContentWarpper>
        <ComparePlans isPersonalMode />
        <ContentWarpper>
          <Question isPersonalMode />
          <TopBtn />
        </ContentWarpper>
      </PricingWrapper>
    </Layout>
  )
}
